import { NextRouter } from "next/router";
import { useEffect } from "react";
import { Url } from "url";
import { BookingPageAction } from "../components/forms/booking/BookingForm.types";
import { SchedulingLink, TimePolicyType } from "../reclaim-api/scheduling-links/SchedulingLinks";
import { TeamRedirectAction } from "../reclaim-api/team/Team";
import { getSessionStorage, setSessionStorage } from "./local-storage";

export type PushHistoryBackRouterLike = Pick<NextRouter, "push" | "asPath">;

const removeQS = (url: string) => url.replace(/\?.*$/, "");

const ON_SITE_HISTORY_STORAGE_KEY = "router.onSiteHistory";
const ON_SITE_HISTORY_SIZE = 10;
const DONT_ADD_TO_HISTORY_PATTERNS: RegExp[] = [
  /\[id\]/, // router.asPath briefly has the "[id]" slug on reloads.  Unsure if this is a problem in our code, or Next.js.
  /^\/\?login=/, // login redirect
];

/**
 * React hook to track history for pushHistoryBack.  Should be put at the root of the App.
 * @param router the current router
 */
export function useRecordHistory(router: PushHistoryBackRouterLike): void {
  useEffect(() => {
    if (!sessionStorage) return;
    const currentPath = router.asPath;

    if (DONT_ADD_TO_HISTORY_PATTERNS.every((rx) => !rx.test(currentPath))) {
      const onSiteHistory: string[] = getSessionStorage(ON_SITE_HISTORY_STORAGE_KEY, []).slice(
        0,
        ON_SITE_HISTORY_SIZE - 1
      );
      if (currentPath !== onSiteHistory[0]) onSiteHistory.unshift(currentPath);
      setSessionStorage(ON_SITE_HISTORY_STORAGE_KEY, onSiteHistory);
    }
  }, [router.asPath]);
}

/**
 * Adds previous history state to end of stack if on-site.  If previous history state is not available falls back to fallbackUrl.  Similar to router.back but re-adds location to stack.
 * @param router the current router
 * @param fallbackUrl a fallback URL to navigate to if no previous state is available (likely case for off-site)
 * @param ignoreQSDiff ignores query strings when looking for non-matching URLs in history
 * @returns a promise which resolves when the router push operation completes
 */
export async function pushHistoryBack(
  router: PushHistoryBackRouterLike,
  fallbackPath: string | Url,
  ignoreQSDiff = false
): Promise<boolean> {
  if (!sessionStorage) return router.push(fallbackPath);
  const currentPath = removeQS(router.asPath);
  // ignore first item, it should be the same as the current
  const [, ...onSiteHistory] = getSessionStorage(ON_SITE_HISTORY_STORAGE_KEY, []) as string[];
  let prevPath: string | undefined;

  if (ignoreQSDiff) {
    prevPath = onSiteHistory.find((path) => removeQS(path) !== currentPath);
  } else if (currentPath !== onSiteHistory[0]) {
    prevPath = onSiteHistory[0];
  }

  if (!prevPath) return router.push(fallbackPath);
  return router.push(prevPath);
}

export const queryObjectToStrParams = <T extends string>(params: Record<string, T | undefined>): string => {
  const strQuery = Object.keys(params).reduce(
    (values, k) => (!!params[k] ? [...values, `${k}=${params[k]}`] : values),
    []
  );
  return strQuery.length ? "?" + strQuery.join("&") : "";
};

export const getBillingUrl = (action?: TeamRedirectAction): string => `/billing${!!action ? `?action=${action}` : ""}`;

export const getHoursUrl = (policy?: TimePolicyType): string =>
  `/settings/hours${!!policy ? `?policy=${policy.toLowerCase()}` : ""}`;

export enum OneOnOneEditFormId {
  SchedulingOptions = "schedulingOptions",
}

export const getOneOnOneEditUrl = (oneOnOneId: number, hashTarget?: OneOnOneEditFormId): string =>
  `/one-on-ones/${oneOnOneId}/edit${!!hashTarget ? "#" + hashTarget : ""}`;

export const getSyncHomeUrl = (credentialId?: number): string =>
  `/sync${credentialId !== undefined ? `?credential=${credentialId}` : ""}`;

export const getSchedulingLinksUrl = () => "/scheduling-links";
export const getSchedulingLinkDetailsUrl = (id: string) => `/scheduling-links/${id}`;
export const getSchedulingLinkEditUrl = (id: string) => `/scheduling-links/${id}/edit`;

export const getGroupLink = (schedulingLink: SchedulingLink) => `/m/${schedulingLink.pageSlug}`;

export const getBookingPageLink = (userSlug: string, bookingSlug: string, action?: BookingPageAction) =>
  `/m/${userSlug}/${bookingSlug}` + (action ? `?action=${action}` : "");

export const getSchedulingLinkMeetingLink = (schedulingLink: SchedulingLink) =>
  new URL(getBookingPageLink(schedulingLink.pageSlug, schedulingLink.slug), window.location.href).toString();

export type MeetingPageAction = "schedule" | "reschedule" | "cancel";

export const getMeetingPageLink = (meetingId: string, tz?: string, action?: MeetingPageAction): string =>
  `/meeting/${meetingId}${queryObjectToStrParams<MeetingPageAction | string>({ tz, action })}`;

export const getMeetingReschedulePageLink = (meetingId: string, tz?: string): string =>
  `${getMeetingPageLink(meetingId)}/reschedule` + (tz ? `?tz=${tz}` : "");
